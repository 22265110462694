import { Suspense, lazy } from "react";
import ReactDOM from "react-dom/client";
import { LoadingComponents } from "./components/LoadingScreen";

import "./styles/tailwind.scss";

const target = document.querySelector("#app");
if (target) {
  const root = ReactDOM.createRoot(target);
  const LazyApp = lazy(() =>
    import(
      /* webpackChunkName: "App" */
      "./App"
    ).then(({ App }) => ({ default: App }))
  );

  root.render(
    <Suspense fallback={<LoadingComponents />}>
      <LazyApp />
    </Suspense>
  );
}
