import { useTheme } from "@sundae/react-hooks";
import { FC, useEffect, useState } from "react";
import cx from "classnames";
import { Loader, SundaeSwapLogo, Text, toolkitCx } from "@sundae/ui-toolkit";

export const LoadingComponents: FC = () => {
  const { theme } = useTheme();
  const [showText, setShowText] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setShowText(true);
    }, 1000);
  }, []);

  return (
    <>
      <div
        aria-busy="true"
        className={cx(
          "relative min-h-screen overflow-hidden",
          { "dark:from-gray-700 dark:to-gray-900": theme === "dark" },
          { "from-white to-gray-300": theme !== "dark" }
        )}
      >
        <div
          className={cx(
            "absolute flex h-96 w-96 flex-col items-center justify-center",
            toolkitCx.recipes.center.both()
          )}
        >
          <SundaeSwapLogo className="h-12 w-12" />

          <Loader className="mt-6" size="medium" />

          <Text tag="p" weight="bold" variant="muted" className="mt-8 h-4">
            {showText && (
              <Text tag="span" className="animate-slide-up-fade">
                {/* To keep this bundle as light as possible, we don't load i18n. */}
                Downloading required components...
              </Text>
            )}
          </Text>
        </div>
      </div>
    </>
  );
};
